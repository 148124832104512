<template>
    <div class="dishes pt">
        <div class="dishes__container _container">
            <div class="dishes__columns">
                <aside class="sidebar" v-if="!excludeСategories">
                    <t-btn-arrow @click="$router.push('/welcome')" />
                    <form class="search-input">
                        <div class="search-input__block">
                            <div class="search-input__img">
                                <img :src="require(`@/assets/img/svg/search.svg`)" :alt="search" />
                            </div>
                            <input
                                type="text"
                                name="q"
                                placeholder="Поиск"
                                class="search-input__input"
                                v-model="searchValue"
                                autocomplete="off"
                                @click="isPopupKeyboad = !isPopupKeyboad"
                            />
                            <div class="search-input__remove" v-if="searchValue" @click="clearInput">
                                <img :src="require(`@/assets/img/svg/remove.svg`)" :alt="remove" />
                            </div>
                        </div>
                    </form>
                </aside>
                <div class="dishes__body">
                    <ul class="menu">
                        <li v-if="cafe_slug === 'it-park'">
                            <a
                                type="button"
                                class="btnFastSets"
                                :class="isFastSets ? `_active` : false"
                                @click="isFastSets = !isFastSets"
                                ><span>Быстрые наборы</span></a
                            >
                        </li>
                        <li v-for="category in categories" :product="product" :key="category.id">
                            <a
                                @click="changeCategory(category.id), (isFastSets = false)"
                                class="menu__item"
                                :class="selectedCategoryId === category.id ? `active` : false"
                                >{{ category.real_name }}</a
                            >
                        </li>
                    </ul>
                    <h1 class="dishes__title">{{ title }}</h1>
                    <div class="search-nothing" v-if="!products.length">
                        {{ searchNothing }}
                    </div>
                    <div class="fast-sets" v-else-if="isFastSets">
                        <div class="fast-sets__blocks">
                            <div class="fast-sets__block" v-for="(i, index) in setsCount" :key="index">
                                <div class="fast-sets__sets">
                                    <div
                                        class="fast-sets__set"
                                        v-for="(product, prIndex) in setProducts[index].products"
                                        :key="product.id"
                                    >
                                        <div class="fast-sets__img">
                                            <img
                                                :src="
                                                    product.image ? product.image : require(`@/assets/img/${nophoto}`)
                                                "
                                                :alt="product.real_name"
                                            />
                                        </div>
                                        <div class="fast-sets__body">
                                            <h4 class="fast-sets__title">
                                                {{
                                                    product.real_name.length > 20
                                                        ? product.real_name.substr(0, 20) + "..."
                                                        : product.real_name
                                                }}
                                            </h4>
                                            <button
                                                class="fast-sets__change"
                                                @click="changeProductInSet(index, prIndex)"
                                            >
                                                <span>Заменить</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="fast-sets__to-pay" v-if="inCart.indexOf(index) === -1">
                                    <button class="fast-sets__btn-to-pay" @click="sendOrder(index)">
                                        Купить за {{ setProducts[index].price }} Р
                                    </button>
                                </div>
                                <div class="fast-sets__to-pay" v-if="inCart.indexOf(index) !== -1">
                                    <button class="fast-sets__btn-to-pay pressed" @click="this.$router.push('/cart/')">
                                        Перейти к оплате
                                    </button>
                                </div>
                            </div>
                            <div class="fast-sets__load">
                                <button class="fast-sets__btn-load-more" @click="loadSets()">
                                    {{ load }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="blocks" v-else>
                        <div
                            class="block"
                            v-for="product in products"
                            :product="product"
                            :key="product.id"
                            :class="product.is_popular ? `popular` : ``"
                        >
                            <div class="block__img">
                                <div class="tag" v-if="product.is_popular">Хит</div>
                                <router-link :to="`/product/${product.id}`">
                                    <img
                                        :src="product.image ? product.image : require(`@/assets/img/${nophoto}`)"
                                        :alt="product.real_name"
                                    />
                                </router-link>
                                <div class="info">
                                    <img src="@/assets/img/svg/info.svg" alt="info" />
                                </div>
                            </div>
                            <div class="block__body">
                                <router-link :to="`/product/${product.id}`" class="block__title">{{
                                    product.real_name
                                }}</router-link>
                                <div class="block__info">
                                    {{ product.intro_text }}
                                </div>
                                <div>
                                    <div
                                        class="counter"
                                        v-if="
                                            orderObj.products_ids.indexOf(product.id) != -1 &&
                                            (product.modifiers.length === 0 || product.modifiers_as_hint)
                                        "
                                    >
                                        <button class="counter__minus">
                                            <img
                                                src="@/assets/img/svg/minus.svg"
                                                alt="minus"
                                                @click="
                                                    changeOrderItem(orderObj.id, getOrderItem(product.id), 'decrease')
                                                "
                                            />
                                        </button>
                                        <span class="counter__price">{{ showPrice(product.id) }} Р</span>
                                        <button class="counter__plus">
                                            <img
                                                src="@/assets/img/svg/plus.svg"
                                                alt="plus"
                                                @click="
                                                    changeOrderItem(orderObj.id, getOrderItem(product.id), 'increase')
                                                "
                                            />
                                        </button>
                                    </div>
                                    <div
                                        class="btn-green add-to-cart"
                                        @click="
                                            addToOrder(
                                                orderObj.id,
                                                product.id,
                                                product.modifiers,
                                                product.modifiers_as_hint
                                            )
                                        "
                                        v-if="
                                            orderObj.products_ids.indexOf(product.id) == -1 &&
                                            (product.modifiers.length === 0 || product.modifiers_as_hint)
                                        "
                                    >
                                        <span>{{ $t("add_cancel.add") }} {{ product.price }} Р</span>
                                    </div>
                                    <div
                                        @click="addToOrder(orderObj.id, product.id, product.modifiers)"
                                        class="btn-green add-to-cart"
                                        v-if="product.modifiers.length > 0 && !product.modifiers_as_hint"
                                    >
                                        <span>{{ $t("btn_more") }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <t-select-combo v-if="isShowCombo" />
        <button class="bag" @click="this.$router.push('/cart/')">
            <img src="@/assets/img/bag.png" alt="bag" />
        </button>
    </div>
    <div class="keyboard-block" v-if="isPopupKeyboad" @click="closePopup">
        <div class="keyboard" @click.stop>
            <div class="keyboard__block">
                <div class="keyboard__container _container">
                    <div class="keyboard__body">
                        <form class="search-input">
                            <div class="search-input__block">
                                <div class="search-input__img">
                                    <img :src="require(`@/assets/img/svg/search.svg`)" :alt="search" />
                                </div>
                                <input
                                    type="text"
                                    name="q"
                                    placeholder="Поиск"
                                    class="search-input__input"
                                    v-model="searchValue"
                                    autocomplete="off"
                                />
                                <div class="search-input__remove" v-if="searchValue" @click="clearInput">
                                    <img :src="require(`@/assets/img/svg/remove.svg`)" :alt="remove" />
                                </div>
                            </div>
                        </form>
                        <div class="keyboard__keys" @click="typeLetter">
                            <button class="keyboard__key key" v-for="(number, index) in 9" :key="index">
                                {{ number }}
                            </button>
                            <button class="keyboard__key key">0</button>
                            <button class="btn btn-green key">
                                <img src="@/assets/img/svg/clear.svg" alt="clear" class="clear" />
                            </button>
                            <button class="btn btn-green btn-long key">
                                {{ $t("cart.btn_clear") }}
                            </button>
                            <button class="keyboard__key key" v-for="letter in letters" :key="letter.id">
                                {{ letter.char }}
                            </button>
                            <button class="btn btn-green key">
                                <img src="@/assets/img/svg/space.svg" alt="space" class="space" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="keyboard__buttons">
                <button class="btn btn-red" @click="clearInput(), closePopup()">
                    {{ $t("cart.cancel") }}
                </button>
                <button class="btnApply" @click="pressDone">
                    {{ $t("cart.btn_apply") }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { api } from "@/api/api";

export default {
    name: "t-dishes",
    data() {
        return {
            isFastSets: false,
            isPopupKeyboad: false,
            isShowCombo: false,
            categories: [],
            selectedCategoryTitle: `Кыстыбургеры`,
            selectedCategoryId: 0,
            products: [],
            setProducts: [],
            info: `info.svg`,
            orderId: localStorage.getItem("orderId"),
            orderObj: {},
            excludeСategories: false,
            searchValue: "",
            searchNothing: `Загрузка блюд...`,
            cafe_slug: "",
            letters: [
                { id: 1, char: `й` },
                { id: 2, char: `ц` },
                { id: 3, char: `у` },
                { id: 4, char: `к` },
                { id: 5, char: `е` },
                { id: 6, char: `н` },
                { id: 7, char: `г` },
                { id: 8, char: `ш` },
                { id: 9, char: `щ` },
                { id: 10, char: `з` },
                { id: 11, char: `х` },
                { id: 12, char: `ъ` },
                { id: 13, char: `ё` },
                { id: 14, char: `ф` },
                { id: 15, char: `ы` },
                { id: 16, char: `в` },
                { id: 17, char: `а` },
                { id: 18, char: `п` },
                { id: 19, char: `р` },
                { id: 20, char: `о` },
                { id: 21, char: `л` },
                { id: 22, char: `д` },
                { id: 23, char: `ж` },
                { id: 24, char: `э` },
                { id: 25, char: `я` },
                { id: 26, char: `ч` },
                { id: 27, char: `с` },
                { id: 28, char: `м` },
                { id: 29, char: `и` },
                { id: 31, char: `т` },
                { id: 32, char: `ь` },
                { id: 33, char: `б` },
                { id: 34, char: `ю` },
                { id: 35, char: `_` },
            ],
            typeLetter() {
                if (event.target.className !== "keyboard__keys" && event.target.className !== "btnApply") {
                    let key = event.target.innerHTML || event.target.className;
                    if (key === "clear") this.searchValue = this.searchValue.substr(0, this.searchValue.length - 1);
                    else if (key === "Очистить") this.searchValue = "";
                    else if (key === "space") this.searchValue += " ";
                    else this.searchValue += key.toUpperCase();
                }
            },
            load: `Загрузить ещё...`,
            setsCount: 0,
            inCart: [],
            nophoto: "nophoto.jpg",
        };
    },
    methods: {
        closePopup() {
            this.isPopupKeyboad = false;
        },
        pressDone() {
            this.isPopupKeyboad = false;
        },
        showPrice: function (product_id) {
            let output = 0;
            this.orderObj.items.forEach(function (item) {
                if (item.product.id == product_id) {
                    output = item.product.price * item.quantity;
                }
            });
            return output;
        },
        getOrderItem: function (product_id) {
            let output = 0;
            this.orderObj.items.forEach(function (item) {
                if (item.product.id == product_id) {
                    output = item.id;
                }
            });
            return output;
        },
        changeCategory: function (categoryId) {
            this.selectedCategoryId = categoryId;
            axios
                .get(api + `products/?category=` + this.selectedCategoryId)
                .then((response) => {
                    this.products = response.data;
                })
                .catch((e) => {
                    this.errors.cafes = e.message;
                });
        },
        addToOrder(order_id, product_id, modifiers, modifiers_as_hint) {
            let self = this;
            if (modifiers.length === 0 || modifiers_as_hint) {
                axios
                    .post(api + `order-add/`, {
                        order_id: order_id,
                        product_id: product_id,
                    })
                    .then(function (response) {
                        self.orderObj = response.data;
                    });
            } else {
                self.$router.push(`/product/${product_id}`);
            }
        },
        changeOrderItem(order_id, order_item_id, type) {
            let self = this;
            axios
                .post(api + `order-change/`, {
                    order_id: order_id,
                    order_item_id: order_item_id,
                    type: type,
                })
                .then(function (response) {
                    self.orderObj = response.data;
                });
        },
        clearInput() {
            var self = this;
            self.searchValue = "";
            axios
                .get(api + `categories/`)
                .then((response) => {
                    self.categories = response.data;
                    self.selectedCategoryId = self.categories[0].id;
                    axios
                        .get(api + `products/?category=` + self.selectedCategoryId)
                        .then((response) => {
                            self.products = response.data;
                            console.log(" this.products", self.products);
                        })
                        .catch((e) => {
                            self.errors.cafes = e.message;
                        });
                })
                .catch((e) => {
                    self.errors.cafes = e.message;
                });
        },
        sendOrder: function (index) {
            let self = this;
            self.setProducts[index].products.forEach(function (item) {
                console.log(item);
                self.addToOrder(self.orderObj.id, item.id, []);
            });
            self.inCart.push(index);
        },
        changeProductInSet(setIndex, productIndex) {
            let self = this;
            let neededProduct = {};
            axios
                .get(api + `products/?category=` + self.setProducts[setIndex].products[productIndex].category.id)
                .then((response) => {
                    if (response.data.length > 1) {
                        console.log(response.data, response.data.length, neededProduct);
                        let runWhile = true;
                        while (runWhile) {
                            let item = response.data[Math.floor(Math.random() * response.data.length)];
                            if (item.id != self.setProducts[setIndex].products[productIndex].id) {
                                neededProduct = item;
                                break;
                            }
                        }
                        self.setProducts[setIndex].products[productIndex] = neededProduct;
                        let newPrice = 0;
                        self.setProducts[setIndex].products.forEach(function (item) {
                            newPrice += item.price;
                        });
                        self.setProducts[setIndex].price = newPrice;
                    }
                })
                .catch((e) => {
                    this.errors.cafes = e.message;
                });
            console.log(setIndex, productIndex);
            console.log(self.setProducts[setIndex]);
            console.log(self.setProducts[setIndex].products[productIndex]);
        },
        loadSets() {
            let self = this;
            self.load = "Идет загрузка";
            axios
                .get(api + `express-sets/`)
                .then((response) => {
                    response.data.forEach(function (item) {
                        self.setProducts.push(item);
                        self.load = "Загрузить еще...";
                    });
                    self.setsCount += response.data.length;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
    watch: {
        searchValue: function (newVal, oldVal) {
            // watch it
            if (newVal.length >= 3) {
                this.selectedCategoryId = 0;
                axios
                    .get(api + `products/?search=` + this.searchValue)
                    .then((response) => {
                        this.products = response.data;
                        console.log(" this.products", self.products);
                    })
                    .catch((e) => {
                        this.errors.cafes = e.message;
                    });
            }
            console.log(`Search was ${oldVal} now - ${newVal}`);
        },
    },
    mounted() {
        if (!this.orderId) {
            this.$router.push("/");
        }
        let self = this;
        axios.get(api + `orders/` + this.orderId + "/").then(function (response) {
            self.orderObj = response.data;
        });
        axios.get(api).then(function (response) {
            self.excludeСategories = response.data.kiosk_exclude_categories;

            if (self.excludeСategories) {
                self.selectedCategoryTitle = "Позиции меню";

                axios
                    .get(api + `products/`)
                    .then((response) => {
                        console.log("yeeeh");
                        self.products = response.data;
                        console.log("this.products", self.products);
                    })
                    .catch((e) => {
                        console.log(e);
                    });
            } else {
                axios
                    .get(api + `categories/`)
                    .then((response) => {
                        self.categories = response.data;
                        self.selectedCategoryId = self.$route.query.category
                            ? Number(self.$route.query.category)
                            : self.categories[0].id;
                        axios
                            .get(api + `products/?category=` + self.selectedCategoryId)
                            .then((response) => {
                                self.products = response.data;
                                console.log(" this.products", self.products);
                            })
                            .catch((e) => {
                                self.errors.cafes = e.message;
                            });
                    })
                    .catch((e) => {
                        self.errors.cafes = e.message;
                    });
            }
        });
        let host = window.location.href;
        let cafe_slug = host.split("/");
        this.cafe_slug = cafe_slug[2].split(".")[0];

        if (host.indexOf("localhost") != -1) {
            this.cafe_slug = "it-park";
        }

        self.loadSets();
    },
};
</script>

<style lang="scss">
@import "~@/assets/scss/vars";
@import "~@/assets/scss/mixins";
.btnFastSets {
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px dashed #d36d51;
    border-radius: 12px;
    background: none;
    @media (max-width: $ms3) {
        width: 100%;
        height: 50px;
        span {
            font-size: 16px !important;
        }
    }
    span {
        font-weight: 400;
        font-size: 35px;
        line-height: 25.75px;
        color: #d36d51;
        background: none;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid #fffff7;
        border-radius: 10px;
    }
    &:hover,
    &:focus {
        span {
            color: #fffff7;
            background: #d36d51;
            transition: 0.2s ease 0.2s;
        }
    }
}
.btnFastSets._active {
    span {
        color: #fffff7;
        background: #d36d51;
        transition: 0.2s ease 0.2s;
    }
}
.fast-sets {
    width: 100%;
    max-width: 751px;
    margin: 3rem auto 0;
    height: 65vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1rem;
    position: relative;
    left: 7px;
    @media (max-width: $ms4) {
        padding: 5px;
    }
    &::-webkit-scrollbar-track {
        background: rgba(40, 115, 95, 0.2);
        border-radius: 10px;
        margin-top: 1rem;
    }
    &::-webkit-scrollbar {
        width: 9px;
    }
    &::-webkit-scrollbar-thumb {
        background: #28735f;
        border-radius: 10px;
    }
    .btn-add {
        background: #dae6de;
        font-family: "Nunito";
        font-size: 18px;
        font-weight: 900;
        line-height: 20px;
        text-align: center;
        color: #81ada0;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1rem;
        &:hover,
        &:focus {
            background: #cde6d5;
            transition: 0.2s ease 0.2s;
        }
    }
    &__blocks {
        display: flex;
        flex-direction: column;
    }
    &__block {
        width: 100%;
        height: auto;
        border-radius: 12px;
        background-color: #f9faf5;
        box-shadow: 0 0 5px rgba(105, 105, 105, 0.25);
        margin-bottom: 1.5rem;
    }
    &__sets {
        display: flex;
        justify-content: center;
        overflow: hidden;
        padding: 0 2.5rem 0.5rem;
        @media (max-width: $ms3) {
            padding: 0 1.5rem 0.5rem;
            flex-wrap: wrap;
        }
        @media (max-width: $ms4) {
            padding: 0 0 0.5rem;
        }
    }
    &__set {
        width: 25%;
        height: auto;
        border-radius: 8px;
        overflow: hidden;
        background-color: #fffff7;
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        margin: 1.5rem 0.5rem 0;
        @media (max-width: $ms3) {
            width: 45%;
        }
        @media (max-width: $ms4) {
            width: 40%;
        }
    }
    &__img {
        height: 140px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            transform: scale(1.3);
        }
    }
    &__title {
        font-size: 20px;
        font-weight: 400;
        line-height: 20.6px;
        color: #0e0e0e;
        @media (max-width: $ms2) {
            font-size: 18px;
        }
        @media (max-width: $ms3) {
            font-size: 14px;
        }
    }
    &__change {
        background: none;
        border: 2px solid #d36d51;
        border-radius: 7px;
        position: relative;
        width: 100%;
        height: 45px;
        padding-left: 2rem;
        margin-top: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        &::before {
            content: url("~@/assets/img/svg/change.svg");
            position: absolute;
            top: 3px;
            bottom: 0;
            left: 10px;
            margin: auto;
            display: flex;
            align-items: center;
            transform: scale(0.75);
        }
        &:hover,
        &:focus {
            opacity: 0.5;
            transition: 0.2s ease 0.2s;
        }
        span {
            font-family: "Nunito";
            font-size: 18px;
            font-weight: 700;
            line-height: 27.28px;
            text-align: center;
            color: #d36d51;
            padding-top: 3px;
            @media (max-width: $ms2) {
                font-size: 16px;
            }
            @media (max-width: $ms3) {
                font-size: 14px;
            }
        }
    }
    &__btn-to-pay {
        width: 95%;
        height: 67px;
        border-radius: 8px;
        background-color: #28735f;
        font-family: "Nunito";
        font-size: 25px;
        font-weight: 800;
        line-height: 34.1px;
        text-align: center;
        color: #fffff7;
        margin: 1rem auto 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover,
        &:focus {
            background-color: #184639;
            transition: 0.2s ease 0.2s;
        }
    }
    &__load {
        display: flex;
        justify-content: center;
    }
    &__btn-load-more {
        width: 320px;
        height: 63px;
        background-color: #dae6de;
        border-radius: 12px;
        font-family: "Nunito";
        font-size: 25px;
        font-weight: 800;
        line-height: 34.1px;
        text-align: center;
        color: #81ada0;
        &:hover,
        &:focus {
            background-color: #81ada0;
            color: #fff;
            transition: 0.2s ease 0.2s;
        }
    }
    &__body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1 1 auto;
        padding: 10px;
        @media (max-width: $ms4) {
            padding: 0;
        }
    }
}
.sidebar {
    display: flex;
    justify-content: space-between;
}
.dishes {
    background-color: #fff !important;
    overflow: hidden;
    @media (max-width: $ms3) {
        height: auto;
        padding-bottom: 5rem;
    }
    .popular {
        .block__body {
            background-color: $red;
        }
        .block__title,
        .block__info {
            color: #fff;
        }
        .add-to-cart {
            background-color: $beige;
            color: $red;
            font-family: "Nunito";
            font-weight: 800;
            &:hover,
            &:focus {
                background-color: #fdfddf;
                transition: 0.2s ease 0.2s;
            }
        }
        .tag {
            background-color: $beige;
            width: 75px;
            height: 35px;
            border-radius: 100px;
            color: $red;
            font-family: "Nunito";
            font-weight: 900;
            font-size: clamp(12px, 5vw, 22px);
            line-height: clamp(12px, 5vw, 30.01px);
            position: absolute;
            top: 10px;
            right: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-shadow: 0px 2px 5px 0px #333;
        }
    }
    background-color: $beige;
    padding-bottom: 5rem;
    position: relative;
    .bag {
        background: none;
        position: fixed;
        bottom: 1rem;
        right: 2rem;
        width: 129px;
        height: 142px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__columns {
        display: flex;
        flex-direction: column;
        @media (max-width: $ms3) {
            padding-bottom: 2rem;
        }
    }
    &__body {
        width: 100%;
        background-color: #fffffd;
        box-shadow: 0 0 15px 0px #eceee5;
        border-radius: 20px;
        margin-top: 2rem;
        padding: 20px 0 0;
    }
    &__title {
        font-weight: 700;
        color: #28735f;
        @include adaptive-font(40, 18, 0);
        @include adaptive-value("line-height", 41, 20, 0);
        position: relative;
        top: 15px;
        margin: 0 0 0 20px;
        @media (max-width: $ms3) {
            margin: 1rem;
        }
    }

    .blocks {
        margin: 20px -18px 0;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 45vh;
        @media (max-width: $ms2) {
            margin: 50px -5px 0;
        }
        @media (max-width: $ms3) {
            margin: 0 auto;
            padding: 0;
        }

        &::-webkit-scrollbar-track {
            background: rgba(40, 115, 95, 0.2);
            border-radius: 10px;
            margin-top: 28px;
        }

        &::-webkit-scrollbar {
            width: 9px;
        }

        &::-webkit-scrollbar-thumb {
            background: #28735f;
            border-radius: 10px;
        }

        .block {
            flex: 0 0 18.3%;
            margin: 10px;
            &__img {
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    box-shadow: 0px -20px 20px 0px rgba(0, 0, 0, 0.7);
                    bottom: -80px;
                    width: 100%;
                    height: 80px;
                }
                .info {
                    width: 18.75px;
                    height: 20px;
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
            @media (max-width: 1027px) {
                flex: 0 0 47%;
            }
            @media (max-width: $ms2) {
                margin: 28px 5px 0;
            }
            @media (max-width: $ms3) {
                min-width: unset;
                flex: 0 0 45%;
            }
        }
    }
}

.menu {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 1212px;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    &::-webkit-scrollbar-track {
        background: none;
        border-radius: 10px;
        margin-top: 15px;
    }

    &::-webkit-scrollbar {
        width: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: none;
        border-radius: 10px;
    }

    @media (max-width: $ms3) {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 1rem;
        max-height: 200px;
        &::-webkit-scrollbar-track {
            background: rgba(40, 115, 95, 0.2);
        }

        &::-webkit-scrollbar {
            width: 9px;
        }

        &::-webkit-scrollbar-thumb {
            background: #28735f;
        }
    }

    li {
        cursor: pointer;
        margin: 5px 10px;
        flex: 0 0 15%;
    }

    &__item {
        border: 1px solid #28735f;
        border-radius: 15px;
        font-size: 35px;
        line-height: 25.75px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #28735f;
        width: 100%;
        height: 55px;
        @media (max-width: $ms3) {
            width: 100%;
            height: 50px;
            font-size: 16px;
            line-height: 20px;
        }

        &:hover,
        &:focus {
            background: #28735f;
            color: #fff;
            transition: 0.2s ease 0.2s;
        }
    }

    .active {
        background: #28735f;
        color: #fff;
    }
}
._input {
    box-shadow: inset 0 0 0 2px #28735f;
    transition: 0.3s ease 0.3s;
}
.search-input {
    width: 100%;
    max-width: 600px;
    height: 65px;
    border-radius: 15px;
    overflow: hidden;
    background-color: #f9faf5;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 1rem 0 1.5rem;
    position: relative;
    @media (max-width: 767px) {
        margin: 2rem auto 0;
    }
    &__block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    &__img {
        width: 34.99px;
        height: 40px;
        @media (max-width: 767px) {
            width: 15px;
            height: 20px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &__input {
        width: 100%;
        padding: 0 1.5rem;
        font-weight: 600;
        line-height: 47.74px;
        color: #28735f;
        font-family: "Nunito", sans-serif;
        font-size: clamp(16px, 5vw, 35px);
        background: none;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 20px;
        outline: none;
        padding: 0 2rem 0 6rem;
        &:focus {
            box-shadow: inset 0 0 0 2px #28735f;
            transition: 0.1s ease 0.1s;
        }
        &::placeholder {
            opacity: 0.5;
            color: inherit;
        }
    }
    &__remove {
        width: 32px;
        height: 32px;
        cursor: pointer;
        z-index: 1;
        &:hover,
        &:focus {
            opacity: 0.5;
            transition: 0.2s ease 0.2s;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
.search-nothing {
    padding: 0 2rem;
    text-align: center;
    font-weight: 600;
    color: #28735f;
    font-family: "Nunito", sans-serif;
    font-size: clamp(14px, 5vw, 24px);
    white-space: pre-line;
    line-height: 1rem;
    margin-top: 5rem;
}
.pressed {
    background-color: #d36d51;
    &:hover,
    &:focus {
        background-color: #d35837;
        transition: 0.2s ease 0.2s;
    }
}
</style>
